<template>
  <div class="d-flex flex-column order-totals">
    <template v-for="total in totals">
    <div :key="total.title" class="sub-total-item" :class="{[total.class] : true}">
      <span class="text-uppercase font-weight-bold" v-html="total.title"></span>
      <span v-if="total.key !== 'discount'">$ {{ total.value.toFixed(2) }}</span>
      <span v-else>- $ {{ total.value.toFixed(2) }}</span>
    </div>
    </template>
    <SectionTitle class="py-0"/>
    <div class="d-flex justify-space-between">
      <span class="text-uppercase font-weight-bold">TOTAL</span>
      <span>$ {{ totalAmount.toFixed(2) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderTotals',
  props: {
    totals: {
      type: Array,
      default: () => ([])
    },
    totalAmount : {
      type: Number,
      default: 0
    }
  },
}
</script>

<style lang="scss">
  .order-totals {
    .section-title {
      margin-bottom: 15px;
    }
    > .sub-total-item {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 15px;
      &:last-child  {
        margin-bottom: 0;
      }
    }
    span {
      font-size: 16px;
      font-weight: 600;
    }
    .rate-text {
      color: #8C8C8C;

    }
  }
</style>
